import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import { useStore } from '@/pinia/store';
import {convertUTC} from '@/mixin'
import dayjs from 'dayjs'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component:  () => import('@/views/LoginAuthenTication/LoginAuthenTication.vue'),
    meta : {
      permissions: 'read_guest'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component:  () => import('@/views/ProfileUser/ProfileUser.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/edit',
    name: 'edit',
    component:  () => import('@/views/ProfileUserEdit/ProfileUserEdit.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/overview',
    name: 'overview',
    component:  () => import('@/views/BookingOverview/BookingOverview.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/BookingHome/BookingHome.vue'),
    meta : {
      permissions: 'read_guest'
    }
  },
  {
    path: '/booking-golf/:Lang',
    name: 'BookingGolf',
    component: () => import('@/views/BookingHomeVer2/BookingHome.vue'),
    meta : {
      permissions: 'read_guest'
    }
  },
  {
    path: '/profile-info',
    name: 'info',
    component: () => import('@/views/ProfileUser/ProfileUser.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/list-booking',
    name: 'List booking',
    component: () => import('@/views/ShoppingCartPages/ShoppingCartPages.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/checkout/:idBooking',
    name: 'checkout',
    component: () => import('@/views/CheckoutBooking/CheckoutBooking.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/payment-booking/:idBooking',
    name: 'payment-booking',
    component: () => import('@/views/PaymentBooking/PaymentBooking.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/confirm-mail/:idBooking/:idMail',
    name: 'Confirm Mail',
    component: () => import('@/views/ConfirmMail/ConfirmMail.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('@/views/CheckInformationbooking/CheckInformationbooking.vue'),
    meta : {
      permissions: 'member'
    }
  },
  {
    path: '/golf-bi',
    name: 'golf-bi',
    component: () => import('@/views/GolfBI/GolfBI.vue'),
    meta : {
      permissions: 'member'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const list = ['golf-bi','login' ]
  if(!list.includes(String(to.name))) {
    localStorage.removeItem('UserName')
    localStorage.removeItem('AccessToken')
    localStorage.removeItem('SessionId')
    localStorage.removeItem('ListReport')
    localStorage.removeItem('SiteName')
    localStorage.removeItem('SessionExpire')
    return next({path: '/'});
  } 
  // else if (to.name == 'golf-bi') {
  //   if(localStorage.getItem('SessionExpire')) {
  //     if(dayjs() < dayjs(localStorage.getItem('SessionExpire'))) {
  //       return next({path: '/golf-bi'});
  //     } else {
  //       localStorage.removeItem('UserName')
  //       localStorage.removeItem('AccessToken')
  //       localStorage.removeItem('SessionId')
  //       localStorage.removeItem('ListReport')
  //       localStorage.removeItem('SiteName')
  //       localStorage.removeItem('SessionExpire')
  //       return next({path: '/'});
  //     }
  //   } else {
  //     localStorage.removeItem('UserName')
  //     localStorage.removeItem('AccessToken')
  //     localStorage.removeItem('SessionId')
  //     localStorage.removeItem('ListReport')
  //     localStorage.removeItem('SiteName')
  //     localStorage.removeItem('SessionExpire')
  //     return next({path: '/'});
  //   }
  // } 
 return next(); 
})

export default router;
